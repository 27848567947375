<template>
<h1 class="text-center">Timelines</h1>

  <ul class="text-center">


    <li class="mt-20 text-3xl">NR. 1

      <TimelineNr1/>
    </li>
    <li class="mt-20 text-3xl">NR. 2

      <TimelineNr2/>
    </li>
    <li class="my-20 text-3xl">NR. 3

      <TimelineNr3/>
    </li>

    <li class="text-3xl">NR. 4

      <TimelineNr4/>
    </li>


    <li class="my-20 text-3xl py-8">NR. 5

      <TimelineNr5/>
    </li>
  </ul>

</template>

<script>

import TimelineNr1 from "../../components/partials/Timelines/TimelineNr1";
import TimelineNr2 from "../../components/partials/Timelines/TimelineNr2";
import TimelineNr3 from "../../components/partials/Timelines/TimelineNr3";
import TimelineNr4 from "../../components/partials/Timelines/TimelineNr4";
import TimelineNr5 from "../../components/partials/Timelines/TimelineNr5";
export default {
  name: "Timelines",

  components: {TimelineNr1,TimelineNr2,TimelineNr3,TimelineNr4,TimelineNr5}

}
</script>
