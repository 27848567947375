<template>

  <div class="container mx-auto sm:px-4 max-w-full mx-auto sm:px-4 text-left">

    <div class="mx-auto  flex flex-wrap  example-basic">
      <div class="md:w-full pr-4 pl-4 example-title">
        <h2>Basic Timeline</h2>
        <p>Extra small devices (phones, less than 768px)</p>
      </div>
      <div class="mx-auto grid sm:w-4/5 pr-4 pl-4 sm:w-2/3 pr-4 pl-4 col-span-2">
        <ul class="timeline">
          <li class="timeline-item">
            <div class="timeline-info">
              <span>March 12, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque.</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>March 23, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
          <li class="timeline-item period">
            <div class="timeline-info"></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h2 class="timeline-title">April 2016</h2>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>April 02, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>April 28, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
        </ul>
      </div>
    </div>





    <div class="row example-split">
      <div class="col-md-12 example-title">
        <h2>Split Timeline</h2>
        <p>Small devices (tablets, 768px and up)</p>
      </div>
      <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
        <ul class="timeline timeline-split">
          <li class="timeline-item">
            <div class="timeline-info">
              <span>March 12, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque.</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>March 23, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
          <li class="timeline-item period">
            <div class="timeline-info"></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h2 class="timeline-title">April 2016</h2>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>April 02, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>April 28, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row example-centered">
      <div class="col-md-12 example-title">
        <h2>Centered Timeline</h2>
        <p>Medium devices (desktops, 992px and up).</p>
      </div>
      <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
        <ul class="timeline timeline-centered">
          <li class="timeline-item">
            <div class="timeline-info">
              <span>March 12, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque.</p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>March 23, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
          <li class="timeline-item period">
            <div class="timeline-info"></div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h2 class="timeline-title">April 2016</h2>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>April 02, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
          <li class="timeline-item">
            <div class="timeline-info">
              <span>April 28, 2016</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
              <h3 class="timeline-title py-2">Event Title</h3>
              <p>Nullam vel sem. Nullam vel sem. Integer ante arcu, accumsan a, consectetuer eget, posuere ut, mauris. Donec orci lectus, aliquam ut, faucibus non, euismod id, nulla. Donec vitae sapien ut libero venenatis faucibus. ullam dictum felis
                eu pede mollis pretium. Pellentesque ut neque. </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TimelineNr2"
}
</script>

<style scoped lang="scss">
$primary-color: #FF6B6B;
$primary-color-hover: scale-color($primary-color, $lightness: 32%);

body {
  color: #768390;
  background: #FFF;
  font-family: "Effra", Helvetica, sans-serif;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
h1,h2,h3,h4,h5,h6 {
  color: #3D4351;
  margin-top: 0;
}
a {
  color: $primary-color;
&:hover {
   color: $primary-color-hover;
   text-decoration: none;
 }
}
.example-header {
  background: #3D4351;
  color: #FFF;
  font-weight: 300;
  padding: 3em 1em;
  text-align: center;
h1 {
  color: #FFF;
  font-weight: 300;
  margin-bottom: 20px
}
p {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}
}
.container-fluid {
.row {
  padding: 0 0 4em 0;
&:nth-child(even) {
   background: #F1F4F5;
 }
}
}

.example-title {
  text-align: center;
  margin-bottom: 60px;
  padding: 3em 0;
  border-bottom: 1px solid #E4EAEC;
p {
  margin: 0 auto;
  font-size: 16px;
  max-width: 400px;
}
}

/*==================================
    TIMELINE
==================================*/

/*-- GENERAL STYLES
------------------------------*/
.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
h1, h2, h3, h4, h5, h6 {
  line-height: inherit;
}
}

/*----- TIMELINE ITEM -----*/

.timeline-item {
  padding-left: 40px;
  position: relative;
&:last-child {
   padding-bottom: 0;
 }
}

/*----- TIMELINE INFO -----*/

.timeline-info {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 .5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}
/*----- TIMELINE MARKER -----*/

.timeline-marker {
  position: absolute;
  top: 0; bottom: 0; left: 0;
  width: 15px;
&:before {
   background: $primary-color;
   border: 3px solid transparent;
   border-radius: 100%;
   content: "";
   display: block;
   height: 15px;
   position: absolute;
   top: 4px; left: 0;
   width: 15px;
   transition: background 0.3s ease-in-out,
   border 0.3s ease-in-out;
 }
&:after {
   content: "";
   width: 3px;
   background: #CCD5DB;
   display: block;
   position: absolute;
   top: 24px; bottom: 0; left: 6px;
 }
.timeline-item:last-child &:after {
                             content: none;
                           }
}
.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid $primary-color;
}

/*----- TIMELINE CONTENT -----*/

.timeline-content {
  padding-bottom: 40px;
p:last-child {
  margin-bottom: 0;
}
}

/*----- TIMELINE PERIOD -----*/

.period {
  padding: 0;
.timeline-info {
  display: none;
}
.timeline-marker {
&:before {
   background: transparent;
   content: "";
   width: 15px;
   height: auto;
   border: none;
   border-radius: 0;
   top: 0;
   bottom: 30px;
   position: absolute;
   border-top: 3px solid #CCD5DB;
   border-bottom: 3px solid #CCD5DB;
 }
&:after {
   content: "";
   height: 32px;
   top: auto;
 }
}
.timeline-content {
  padding: 40px 0 70px;
}
.timeline-title {
  margin: 0;
}
}

h2.timeline-title {

  font-size: 3rem;
}

h3.timeline-title {

  font-size: 2rem;
  font-weight: bold;

}

/*----------------------------------------------
    MOD: TIMELINE SPLIT
----------------------------------------------*/

.timeline-split {
@media (min-width: 768px) {
  .timeline {
    display: table;
  }
  .timeline-item {
    display: table-row;
    padding: 0;
  }
  .timeline-info,
  .timeline-marker,
  .timeline-content,
  .period .timeline-info {
    display: table-cell;
    vertical-align: top;
  }
  .timeline-marker {
    position: relative;
  }
  .timeline-content {
    padding-left: 30px;
  }
  .timeline-info {
    padding-right: 30px;
  }
  .period .timeline-title {
    position: relative;
    left: -45px;
  }
}
}

/*----------------------------------------------
    MOD: TIMELINE CENTERED
----------------------------------------------*/

.timeline-centered {
  @extend .timeline-split;
@media (min-width: 992px) {
&,
  .timeline-item,
  .timeline-info,
  .timeline-marker,
  .timeline-content {
    display: block;
    margin: 0;
    padding: 0;
  }
  .timeline-item {
    padding-bottom: 40px;
    overflow: hidden;
  }
  .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px;
  }
  .timeline-info,
  .timeline-content {
    width: 50%;
  }
  > .timeline-item:nth-child(odd) .timeline-info {
    float: left;
    text-align: right;
    padding-right: 30px;
  }
  > .timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 30px;
  }
  > .timeline-item:nth-child(even) .timeline-info {
    float: right;
    text-align: left;
    padding-left: 30px;
  }
  > .timeline-item:nth-child(even) .timeline-content {
    float: left;
    text-align: right;
    padding-right: 30px;
  }
  > .timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .timeline-item.period {
    padding: 50px 0 90px;
  }
  .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto;
  }
  .period .timeline-title {
    left: auto;
  }
}
}

/*----------------------------------------------
    MOD: MARKER OUTLINE
----------------------------------------------*/

.marker-outline {
.timeline-marker {
&:before {
   background: transparent;
   border-color: $primary-color;
 }
}
.timeline-item:hover .timeline-marker:before {
  background: $primary-color;
}
}

</style>