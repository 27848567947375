<template>


  <div class='w-full mt-8 flex'>
    <div class='timeline'>
      <div class='start'></div>
    </div>
    <div class='entries'>
      <div class='entry project'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            April 2013
          </div>
          <div class='detail'>
            Launched <a href="https://youtube.com/charbytes">Charbytes</a>
          </div>
        </div>
      </div>
      <div class='entry study'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            September 2012
          </div>
          <div class='detail'>
            Began Silversmithing degree @ London Met
          </div>
        </div>
      </div>
      <div class='entry project'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            July 2012
          </div>
          <div class='detail'>
            Launched <a href="http://pentatopejewellery.com">Pentatope</a> Jewellery
          </div>
        </div>
      </div>
      <div class='entry study'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            September 2011
          </div>
          <div class='detail'>
            Started Foundation degree @ Central Saint Martins
          </div>
        </div>
      </div>
      <div class='entry life'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            June 2011
          </div>
          <div class='detail'>
            Left School
          </div>
        </div>
      </div>
      <div class='entry project'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            May 2010
          </div>
          <div class='detail'>
            Released first tumblr theme <a href="http://pouretrebelle.com/post/563994536/ive-made-my-first-real-theme" title="no this is really bad don't click on it">Belle</a>
          </div>
        </div>
      </div>
      <div class='entry project'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            September 2009
          </div>
          <div class='detail'>
            Started <a href="http://pouretrebelle.com">Pour &Ecirc;tre Belle</a>
          </div>
        </div>
      </div>
      <div class='entry life'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            November 2005
          </div>
          <div class='detail'>
            Moved To Bath
          </div>
        </div>
      </div>
      <div class='entry life'>
        <div class='dot'></div>
        <div class='label'>
          <div class='time'>
            July 1993
          </div>
          <div class='detail'>
            Born In Buckinghamshire
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TimelineNr4"
}
</script>

<style scoped lang="scss">


$bg: #fcfcfc;

$timeline-bg: beige;//#e7eff1;
$timeline-height: 0.7rem;
$timeline-border: #fff;
$timeline-shadow: 0 0 2rem 0 rgba(#000,0.1), inset 0 0 0.4em rgba(#000,0.1);
$timeline-border-width: 0.2rem;

$dot-bg: powderblue;
$dot-hover-bg: thistle;
$dot-size: 1.2rem;
$dot-hover-size: 1.6rem;
$dot-border: #fff;
$dot-shadow: 0 0.1rem 1rem 0 rgba(#000,0.1), inset 0 0 0.4em rgba(#fff,0.1), inset 0 0.4rem 0.1rem rgba(#fff,0.3);
$dot-border-width: 0.16rem;

$notch-size: 1rem;

$label-bg: #E1F0EE;
$label-hover-bg: darken($label-bg,5);
$label-padding: 0.4rem;
$label-divider: 1px solid rgba(#fff,0.8);

$scroll-thumb: transparent;//#f1f5f7;

@import url(https://fonts.googleapis.com/css?family=PT+Sans);

body {
  margin: 0;
  font-family: PT Sans, sans-serif;
  background-color: $bg;
  color: #000;
}

.container {
  margin: 3rem 0;
  width: 100%;
  position: relative;
  overflow-x: scroll;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &::-webkit-scrollbar {
    height: 45px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scroll-thumb;
    background-clip: content-box;
    border-radius: 100%;
    border: 20px solid transparent;
  }


}
.entries {
  margin-right: -10000px;
  margin-left: 1rem;
}

.timeline {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: $timeline-height;
  background-color: $timeline-bg;
  background-clip: content-box;
  border-width: $timeline-border-width 0;
  border-style: solid;
  border-color: $timeline-border;
  box-shadow: $timeline-shadow;
}
.entry {
  float: left;
  max-width: 170px;
  position: relative;
  text-align: center;
  margin: 0 1rem;
  margin-top: $timeline-height;
  .dot {
    position: absolute;
    width: $dot-size;
    height: $dot-size;
    background-color: $dot-bg;
    left: 50%;
    margin-left: -1/2*$dot-size;
    margin-top: -(1/2)*$dot-size+(-1/2)*$timeline-height;
    border-radius: 100%;
    box-sizing: border-box;
    background-clip: content-box;
    border-width: $dot-border-width;
    border-style: solid;
    border-color: $dot-border;
    box-shadow: $dot-shadow;
    transition :height 0.2s linear, width 0.2s linear, margin 0.2s linear, background-color 0.3s ease-out;
  }
}


.entry:hover {
  .dot {
    width: $dot-hover-size;
    height: $dot-hover-size;
    margin-left: -1/2*$dot-hover-size;
    margin-top: -(1/2)*$dot-hover-size+(-1/2)*$timeline-height;
    background-color: $dot-hover-bg;
  }
  .label {
    margin-top: 2.5rem;
    background-color: $label-hover-bg;
    border-bottom: 0.5rem solid transparent;
  }
}

.label {
  display: inline-block;
  position: relative;
  background-color: $label-bg;
  background-clip: padding-box;
  margin-top: 3rem;
  padding: $label-padding 0.8rem 0.8rem;
  border-radius: 0.2rem;
  transition :margin-top 0.2s linear, background-color 0.2s linear, border-bottom 0.2s linear;
  border-bottom: 0 solid transparent;
  .time {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 0 0 0.4rem;
    padding: 0 0.3rem 0.3rem;
    letter-spacing: 1px;
    display: inline-block;
    border-bottom: $label-divider;
  }
  .detail {
    font-size: 1rem;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: inherit;
    background-clip: content-box;
    box-sizing: border-box;
    width: $notch-size;
    height: $notch-size;
    left: 50%;
    margin-top: -$label-padding+-1/2*$notch-size;
    margin-left: -1/2*$notch-size;
    transform-origin: center;
    transform : rotate(45deg);
    z-index: -1;
  }
  a {
    color: #000;
    text-decoration: none;
    &:before {
      content: '[';
    }
    &:after {
      content: ']';
    }
  }
}


.entry.life .label {
  background-color: adjust-hue($label-bg,-40);
}
.entry.life:hover .label {
  background-color: darken(adjust-hue($label-bg,-40),5);
}
.entry.study .label {
  background-color: adjust-hue($label-bg,40);
}
.entry.study:hover .label {
  background-color: darken(adjust-hue($label-bg,40),5);
}

</style>